<template>
    <app-page v-hotkey="shortcuts" :no-container="true">
        <template #page-header>
            <page-header :name="projectName" :link="'/project/'+projectName" :loading="loading" no-search :no-container="true">
                <a v-if="domains.length" :href="`https://`+domains[0]" target="_blank" class="btn btn-light mr-2" :title="domains[0]">
                    <i class="fas fa-link fa-fw"></i> Open
                </a>
                <a :href="'https://github.com/Eye-InMedia/'+projectName" target="_blank" class="btn btn-light mr-2" title="Github">
                    <i class="fab fa-github fa-fw"></i> Github
                </a>
                <a :href="'http://jenkins.eyeinsansfil.com:8080/job/'+projectName+'%20'+$store.state.cluster.toUpperCase()+ '/'" target="_blank"
                   class="btn btn-light" title="Jenkins">
                    <i class="fab fa-jenkins fa-fw"></i> Jenkins
                </a>
                <b-alert v-if="crashingPod" show variant="danger">Pod is crashing or not ready. Only logs are enabled.</b-alert>
                <div v-if="pods.length >= 2" class="d-flex align-items-center">
                    <b-btn v-for="pod in pods" @click="setCurrentPod(pod)"
                           :key="'filebtn-'+pod.name" class="m-1"
                           :variant="currentPod.name === pod.name ? 'primary' : 'light'">
                        {{ pod.name.replace(projectName + '-', '') }}
                    </b-btn>
                    <b-checkbox v-model="applyToAllPods">Apply changes to all pods</b-checkbox>
                </div>
            </page-header>
        </template>
        <div class="p-3">
            <b-tabs v-model="currentTab" class="main-tabs" content-class="mt-3" align="center" pills>
                <b-tab title="Logs" @click="loadLogs">
                    <b-overlay :show="loading">
                        <b-btn variant="danger" v-if="failedPods.length > 0" @click="deleteAllFailedPods">Delete {{ failedPods.length }} failed pods</b-btn>

                        <section class="pt-3" v-for="pod of pods" :key="'logsTerminal-' + pod.name">
                            <h4>
                                {{ pod.name }}
                                <small class="mx-1">
                                    <b-badge :variant="pod.status === `Running` ? `success` : `danger`" class="mr-1">{{ pod.status }}</b-badge>
                                    <em>({{ pod.startTime | dateAgo }}, {{ pod.restarts }} restarts)</em>
                                </small>
                                <e-button-confirm size="sm" variant="danger" @confirmed="deletePod(pod)"><i class="fas fa-trash"></i> Delete</e-button-confirm>
                            </h4>
                            <pre>kube{{ $store.state.cluster === `demo` ? `live` : $store.state.cluster }} logs -f {{ pod.name }}</pre>
                            <div :ref="'logsTerminal-' + pod.name"></div>
                        </section>
                    </b-overlay>
                </b-tab>
                <b-tab title="Files" :disabled="crashingPod">
                    <b-overlay :show="loading">
                        <div class="pt-3">
                            <b-breadcrumb>
                                <b-breadcrumb-item v-for="(path, index) in currentPaths" :active="index === currentPaths.length-1" :key="'path-'+path.path" @click="openDirWithPath(path.path)">
                                    {{ path.name }}
                                </b-breadcrumb-item>
                            </b-breadcrumb>

                            <b-row>
                                <b-col :cols="packageJSONCommands.length > 0 ? 9 : 12">
                                    <template v-if="currentFile">
                                        <b-btn class="mb-2" @click="replaceFileContent">Save</b-btn>
                                        <div class="codemirror-container text-left">
                                            <codemirror v-model="currentFile.content" style="height: auto" :options="cmOptions"></codemirror>
                                        </div>
                                        <b-btn class="mt-2" @click="replaceFileContent">Save</b-btn>
                                    </template>
                                    <template v-else>
                                        <b-list-group class="text-left">
                                            <b-list-group-item v-for="file in files" :key="'file-'+file.name">
                                                <a @click="open(file)" class="pointer">
                                                    <i class="fas fa-folder" v-if="file.dir"></i>
                                                    <i class="fas fa-file" v-else></i>
                                                    {{ file.name }}
                                                </a>
                                            </b-list-group-item>
                                        </b-list-group>
                                    </template>
                                </b-col>
                                <b-col v-if="packageJSONCommands.length > 0" cols="3">
                                    <b-list-group>
                                        <b-list-group-item @click="sendSocketCommand(command)" v-for="command in packageJSONCommands"
                                                           :disabled="commandExecuting"
                                                           variant="primary" button :key="command.name">{{ command.name }}
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-col>
                            </b-row>
                        </div>
                    </b-overlay>
                </b-tab>
                <b-tab title="Bash" @click="loadBash" :disabled="crashingPod">
                    <b-overlay :show="loading">
                        <section class="pt-3" v-for="pod of pods" :key="'bashTerminal-' + pod.name">
                            <h4>{{ pod.name }}</h4>
                            <pre>kube{{ $store.state.cluster === `demo` ? `live` : $store.state.cluster }} exec -it {{ pod.name }} -- bash</pre>
                            <div :ref="'bashTerminal-' + pod.name"></div>
                        </section>
                    </b-overlay>
                </b-tab>
            </b-tabs>
        </div>

        <b-modal id="commandsModal" size="lg" static scrollable>
            <section v-for="pod of pods" :key="'commandsTerminal-'+pod.name">
                <div class="m-1" :ref="'commandsTerminal-'+pod.name"></div>
            </section>
        </b-modal>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";
import "../../node_modules/xterm/css/xterm.css"
import {Terminal} from "xterm";
import {WebLinksAddon} from 'xterm-addon-web-links';
import {FitAddon} from 'xterm-addon-fit';
import {AttachAddon} from 'xterm-addon-attach';
import {codemirror} from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/vue/vue.js'
import 'codemirror/mode/php/php.js'
import 'codemirror/mode/yaml/yaml.js'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/sass/sass.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/mode/python/python.js'
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";

export default {
    name: `pods`,
    components: {EButtonConfirm, codemirror},
    data() {
        return {
            loading: true,
            projectName: null,
            pods: [],
            domains: [],
            currentPod: null,
            crashingPod: false,
            applyToAllPods: true,
            currentTab: 0,

            absolutePath: `/`,
            files: [],
            currentFile: null,
            packageJSONCommands: [],

            logsLoaded: false,
            logsTerminal: {},
            logsWebsocket: {},

            bashLoaded: false,
            bashTerminal: {},
            bashWebsocket: {},

            commandsLoaded: false,
            commandsTerminal: {},
            commandsWebsocket: {},
            commandExecuting: false
        }
    },
    computed: {
        mode() {
            if (!this.currentFile) {
                return `text`;
            }

            const extension = this.currentFile.name.split(`.`).pop();

            switch (extension) {
                case `vue`:
                    return `vue`;
                case `js`:
                case `cjs`:
                    return `javascript`;
                case `ts`:
                    return {
                        name: `javascript`,
                        typescript: true
                    };
                case `php`:
                    return `php`;
                case `yaml`:
                    return `yaml`;
                case `sass`:
                case `scss`:
                    return `sass`;
                case `py`:
                    return `python`;
                case `xml`:
                    return `xml`;
                case `html`:
                    return `htmlmixed`;
                case `css`:
                    return `css`;
                case `json`:
                    return {
                        name: `javascript`,
                        json: true,
                        statementIndent: 2
                    };
                default:
                    return `text`;
            }
        },
        cmOptions() {
            return {
                tabSize: 4,
                mode: this.mode,
                theme: `monokai`,
                lineNumbers: true,
                line: true,
                styleActiveLine: true,
                extraKeys: {
                    'Ctrl-S': () => {
                        this.replaceFileContent();
                        return false;
                    }
                }
            }
        },
        currentPaths() {
            if (!this.absolutePath) {
                return [];
            }

            if (this.absolutePath === `/`) {
                return [{
                    name: ``,
                    path: `/`
                }];
            }

            const parts = this.currentFile ? this.currentFile.path.split(`/`) : this.absolutePath.split(`/`);
            parts.shift();
            let path = ``;
            let result = [];
            for (const part of parts) {
                path += `/` + part
                result.push({
                    name: part,
                    path: path
                })
            }
            result.unshift({
                name: ``,
                path: `/`
            });

            return result;
        },
        shortcuts() {
            return {
                'ctrl+s': this.replaceFileContent
            }
        },
        failedPods() {
            return this.pods.filter(p => p.status === `Failed`);
        }
    },
    async activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );

        this.init();
    },
    deactivated() {
        this.unwatch();
        this.clearWebsockets();
    },
    beforeDestroy() {
        this.clearWebsockets();
    },
    methods: {
        async init() {
            this.loading = true;
            this.currentTab = this.$route.params.tab ? +this.$route.params.tab : 0;
            this.projectName = this.$route.params.name;
            this.logsLoaded = false;
            this.bashLoaded = false;
            this.commandsLoaded = false;
            this.commandExecuting = false;
            this.currentFile = null;
            this.absolutePath = `/`;

            this.clearWebsockets();

            let resp = await Network.get(`/api/project/pods/${this.$store.state.cluster}/${this.projectName}`);
            this.domains = resp.data.domains;
            this.pods = resp.data.pods;

            if (this.pods.length === 0) {
                this.$router.push(`/project/${this.projectName}`);
                return;
            }

            this.currentPod = this.pods[0];

            if (!this.currentPod.ready) {
                this.crashingPod = true;
                this.currentTab = 0;
                this.$nextTick(() => {
                    this.loadLogs();
                });
                this.loading = false;
                return;
            }

            if (this.currentTab === 0) {
                this.$nextTick(() => {
                    this.loadLogs();
                });
            } else if (this.currentTab === 2) {
                this.$nextTick(() => {
                    this.loadBash();
                });
            }

            try {
                let resp = await Network.post(`/api/pod/exec`, {
                    cluster: this.$store.state.cluster,
                    pod: this.currentPod.name,
                    command: `pwd`
                }, {timeout: 3000});

                this.setAbsolutePath(resp.data);

                this.getCurrentDirectory();

                this.$nextTick(() => {
                    this.loadCommands();
                });
            } catch {
                this.loading = false;
            }
        },
        async loadLogs() {
            if (this.logsLoaded) {
                return;
            }

            this.logsLoaded = true;

            for (const pod of this.pods) {
                if (pod.status === `Failed`) {
                    continue;
                }
                this.logsTerminal[pod.name] = new Terminal({convertEol: true});

                this.logsTerminal[pod.name].loadAddon(new WebLinksAddon());

                const fitAddon = new FitAddon();
                this.logsTerminal[pod.name].loadAddon(fitAddon);

                this.logsTerminal[pod.name].open(this.$refs[`logsTerminal-` + pod.name][0]);
                fitAddon.fit();

                this.logsWebsocket[pod.name] = await Network.websocketConnect(`/api/pod/logs/${this.$store.state.cluster}/${pod.name}`);
                this.logsWebsocket[pod.name].onmessage = event => {
                    this.logsTerminal[pod.name].write(event.data);
                };
            }
        },
        async loadBash() {
            if (this.bashLoaded) {
                return;
            }
            this.bashLoaded = true;

            for (const pod of this.pods) {
                if (pod.status === `Failed`) {
                    continue;
                }
                this.bashTerminal[pod.name] = new Terminal({convertEol: true});

                this.bashTerminal[pod.name].loadAddon(new WebLinksAddon());

                const fitAddon = new FitAddon();
                this.bashTerminal[pod.name].loadAddon(fitAddon);

                this.bashTerminal[pod.name].open(this.$refs[`bashTerminal-` + pod.name][0]);
                fitAddon.fit();

                this.bashWebsocket[pod.name] = await Network.websocketConnect(`/api/pod/bash/${this.$store.state.cluster}/${pod.name}`);
                const attachAddon = new AttachAddon(this.bashWebsocket[pod.name]);
                this.bashTerminal[pod.name].loadAddon(attachAddon);
            }
        },
        async loadCommands() {
            if (this.commandsLoaded) {
                return;
            }
            this.commandsLoaded = true;

            for (const pod of this.pods) {
                if (pod.status === `Failed`) {
                    continue;
                }

                this.commandsTerminal[pod.name] = new Terminal({convertEol: true});

                this.commandsTerminal[pod.name].loadAddon(new WebLinksAddon());

                const fitAddon = new FitAddon();
                this.commandsTerminal[pod.name].loadAddon(fitAddon);

                this.commandsTerminal[pod.name].open(this.$refs[`commandsTerminal-` + pod.name][0]);
                fitAddon.fit();

                this.commandsWebsocket[pod.name] = await Network.websocketConnect(`/api/pod/exec/${this.$store.state.cluster}/${pod.name}`);
                this.commandsWebsocket[pod.name].onmessage = event => {
                    if (event.data === `eyein_abort`) {
                        this.commandExecuting = false;
                    } else {
                        this.commandsTerminal[pod.name].write(event.data);
                    }
                };
            }
        },
        setAbsolutePath(path) {
            let absolutePath = path.trim();
            if (absolutePath === ``) {
                absolutePath = `/`;
            }

            if (absolutePath.substring(0, 2) === `//`) {
                absolutePath = absolutePath.substring(1);
            }

            this.absolutePath = absolutePath;
        },
        setCurrentPod(pod) {
            this.currentPod = pod;
            if (this.currentFile) {
                this.getCurrentFileContent();
            } else {
                this.getCurrentDirectory();
            }
        },
        deletePod(pod) {
            Network.delete(`/api/pod/${this.$store.state.cluster}/${pod.name}`)
                .then(() => {
                    this.init();
                })
        },
        deleteAllFailedPods() {
            for (const pod of this.failedPods) {
                Network.delete(`/api/pod/${this.$store.state.cluster}/${pod.name}`)
                    .then(() => {
                        this.pods = this.pods.filter(p => p.name !== pod.name);
                    })
                    .catch(e => console.error(e));
            }
        },
        open(file) {
            if (file.dir) {
                if (file.name === `..`) {
                    const pathParts = this.absolutePath.split(`/`);
                    pathParts.pop();
                    this.setAbsolutePath(pathParts.join(`/`))
                } else {
                    this.setAbsolutePath(this.absolutePath + `/` + file.name)
                }

                this.getCurrentDirectory();
            } else {
                this.currentFile = file;
                this.getCurrentFileContent();
            }
        },
        openDirWithPath(path) {
            this.setAbsolutePath(path);
            this.getCurrentDirectory();
        },
        async getCurrentDirectory() {
            this.loading = true;
            this.currentFile = null;

            const resp = await Network.post(`/api/pod/exec`, {
                cluster: this.$store.state.cluster,
                pod: this.currentPod.name,
                command: [`ls`, `-larth`, this.absolutePath]
            });

            const lines = resp.data.split(`\n`);
            lines.shift();
            lines.pop();

            let files = [];
            for (const line of lines) {
                let temp = line.split(` `);
                let file = {
                    name: temp.pop(),
                    dir: temp.shift()[0] === `d`,
                    content: ``
                }

                if (file.name === `.`) {
                    continue;
                }

                if (this.absolutePath === `/`) {
                    file.path = `/` + file.name;
                } else {
                    file.path = this.absolutePath + `/` + file.name;
                }

                if (file.name === `package.json`) {
                    try {
                        const resp = await Network.post(`/api/pod/exec`, {
                            cluster: this.$store.state.cluster,
                            pod: this.currentPod.name,
                            command: [`cat`, file.path]
                        });

                        const packageJSON = resp.data;
                        this.packageJSONCommands = [];
                        for (const command in packageJSON.scripts) {
                            this.packageJSONCommands.push({
                                name: command === `start` ? `npm start` : `npm run ${command}`,
                                command: command === `start` ? [`npm`, `start`] : [`npm`, `run`, command]
                            });
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
                files.push(file);
            }

            files.sort((a, b) => {
                if ((a.dir && b.dir) || (!a.dir && !b.dir)) {
                    return a.name.localeCompare(b.name);
                } else {
                    return b.dir - a.dir;
                }
            });

            this.files = files;

            this.loading = false;
        },
        async getCurrentFileContent() {
            this.loading = true;
            const resp = await Network.post(`/api/pod/exec`, {
                cluster: this.$store.state.cluster,
                pod: this.currentPod.name,
                command: [`cat`, this.currentFile.path]
            });
            this.currentFile.content = typeof resp.data === `string` ? resp.data : JSON.stringify(resp.data);
            this.loading = false;
        },
        async replaceFileContent() {
            if (!this.currentFile) {
                return;
            }

            if (this.applyToAllPods) {
                for (const pod of this.pods) {
                    await Network.post(`/api/pod/file/replace`, {
                        cluster: this.$store.state.cluster,
                        pod: pod.name,
                        content: this.currentFile.content,
                        file_path: this.currentFile.path
                    });
                }
            } else {
                await Network.post(`/api/pod/file/replace`, {
                    cluster: this.$store.state.cluster,
                    pod: this.currentPod.name,
                    content: this.currentFile.content,
                    file_path: this.currentFile.path
                });
            }
        },
        sendSocketCommand(command) {
            this.commandExecuting = true;
            this.$bvModal.show(`commandsModal`);
            if (this.applyToAllPods) {
                for (const pod of this.pods) {
                    this.commandsTerminal[pod.name].clear();
                    this.commandsWebsocket[pod.name].send(JSON.stringify(command));
                }
            } else {
                this.commandsTerminal[this.currentPod.name].clear();
                this.commandsWebsocket[this.currentPod.name].send(JSON.stringify(command));
            }
        },
        clearWebsockets() {
            for (const podName in this.bashWebsocket) {
                this.bashWebsocket[podName].send(`eyein_abort`);
                this.bashWebsocket[podName].close();
                delete this.bashWebsocket[podName];
            }

            for (const podName in this.logsWebsocket) {
                this.logsWebsocket[podName].send(`eyein_abort`);
                this.logsWebsocket[podName].close();
                delete this.logsWebsocket[podName];
                this.$refs[`logsTerminal-` + podName][0].innerHTML = ``;
            }

            for (const podName in this.commandsWebsocket) {
                this.commandsWebsocket[podName].close();
                delete this.commandsWebsocket[podName];
            }
        }
    }
}
</script>

<style lang="scss" scoped>
pre {
    border: 3px solid #b4b4b4;
    border-radius: 5px;
    background: #e0e0e0;
}

.codemirror-container::v-deep .CodeMirror {
    height: auto;
}
</style>
